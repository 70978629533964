import axios from "../plugins/axios";

export const getAllTransactions = async () => {
    const response = await axios.get("/api/transactions");
    return response
}

export const getDailyTransactions = async (payload) => {
    const response = await axios.post("/api/transactions/daily", payload);
    return response;
}

export const getRangeTransactions = async (payload) => {
    console.log("MMMMMM", payload)
    const response = await axios.post("/api/transactions/range", payload);
    return response;
}
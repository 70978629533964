import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  createWareHouse,
  updateWarehouse,
  deleteWarehouse,
  getActiveWarehouse,
  getRemovedWarehouse,
  undoWarehouse,
} from "../services/warehouseAPi";
import { toast } from "react-toastify";
import { message } from "antd";

const intialState = {
  isSuccess: false,
  isError: false,
  isLoading: false,
  warehouse: {},
  warehouses: [],
};

export const addWarehouseReducer = createAsyncThunk(
  "add/warehose",
  async (warehousedat, thunkApi) => {
    try {
      return await createWareHouse(warehousedat);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getRemovedWarehouseReducer = createAsyncThunk(
  "get/RemovedWarehouse",
  async (warehousedata, thunkapi) => {
    try {
      return await getRemovedWarehouse(warehousedata);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  }
);

export const getActiveWarehouseReducer = createAsyncThunk(
  "get/ActiveWarehouse",
  async (warehousedata, thunkapi) => {
    try {
      console.log("ASDFGHJKL");
      return await getActiveWarehouse(warehousedata);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  }
);

export const updateWarehouseREducer = createAsyncThunk(
  "warehouse/update",
  async (param, thunkAp) => {
    try {
      return await updateWarehouse(param);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAp.rejectWithValue(message);
    }
  }
);

//
export const deleteWarehouseReducer = createAsyncThunk(
  "delet/warehouse",
  async (para, thinkApi) => {
    try {
      return await deleteWarehouse(para);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thinkApi.rejectWithValue(message);
    }
  }
);
//

export const undoWarehouseReducer = createAsyncThunk(
  "undo/user",
  async (para, thinkApi) => {
    try {
      return await undoWarehouse(para);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thinkApi.rejectWithValue(message);
    }
  }
);
//

export const warehouseSlice = createSlice({
  name: "warehouse",
  initialState: intialState,
  reducers: {
    getAllWarehouse: () => {
      //
    },
    editWarehouse: (state, payload) => {
      state.warehouse = payload;
    },
    deleteWarehouseInternal: (state, payload) => {
      state.warehouses = state.warehouses?.filter(
        (record) => record._id != payload,
        toast.success("Deleted")
      );
    },
  },

  extraReducers: (paraa) => {
    paraa
      .addCase(addWarehouseReducer.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(addWarehouseReducer.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.isError = false;
        state.warehouse = action.payload;
        toast.success("Warehouse Added successfully");
      })
      .addCase(addWarehouseReducer.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        toast.success(action.payload);
      })
      .addCase(getRemovedWarehouseReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRemovedWarehouseReducer.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.isError = false;
        state.warehouses = action?.payload?.data;
      })
      .addCase(getRemovedWarehouseReducer.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        toast.error(action.payload);
      })
      .addCase(getActiveWarehouseReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getActiveWarehouseReducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.warehouses = action.payload?.data;
      })
      .addCase(getActiveWarehouseReducer.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        toast.error(action.payload);
      })
      .addCase(updateWarehouseREducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateWarehouseREducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        toast.success("Warehouse updated successfully");
      })
      .addCase(updateWarehouseREducer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        toast.error(action.payload);
      })
      .addCase(deleteWarehouseReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteWarehouseReducer.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        const record1 = action.payload;
        state.warehouses = state.warehouses?.filter(
          (record) => record._id !== record1.data?.warehouse?._id
        );
        toast.success("Warehouse deleted successfully");
      })
      .addCase(deleteWarehouseReducer.rejected, (state, action) => {
        state.isSuccess = false;
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(undoWarehouseReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(undoWarehouseReducer.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        const record1 = action.payload;
        state.warehouses = state.warehouses?.filter(
          (record) => record._id !== record1.data?.warehouse?._id
        );
        console.log("MMNNLLDD", action.payload);
        toast.success("Warehouse deleted successfully");
      })
      .addCase(undoWarehouseReducer.rejected, (state, action) => {
        state.isSuccess = false;
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
        toast.error(action.payload);
      });
    //
  },
});
export const isError = (state) => state.warehouse.isError;
export const isSuccess = (state) => state.warehouse.isSuccess;
export const isLoading = (state) => state.warehouse.isLoading;

export const warehouses = (state) => state.warehouse.warehouses;
export const { editWarehouse, deleteWarehouseInternal } =
  warehouseSlice.actions;
export default warehouseSlice.reducer;

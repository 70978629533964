import axios from "../plugins/axios";

export const loginUsr = async (cridentials) => {
  console.log("KKKK" + cridentials.id);
  const responsebkc = await axios.post("/api/users/login", cridentials);
  return responsebkc;
};

export const logoutUsr = async () => {
  const response = await axios.get("api/users/logout");
  return response;
};

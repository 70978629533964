import React, { useState } from 'react';
import { Button } from 'antd'; // Import Ant Design Button component
import axios from 'axios';
import { ConvertToUTC } from '../../utils/moment';

const DownloadButton = ({ endPoint, fileName, filter }) => {
  const [loading, setLoading] = useState(false); // Manage loading state

  const handleDownload = async () => {
    setLoading(true); // Set loading to true when the download starts
    try {
      const response = await axios.post(endPoint, filter, {
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: response.data.type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${fileName}_${ConvertToUTC(new Date())}.XLSX`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download error:', error);
    } finally {
      setLoading(false); // Set loading to false when the download finishes
    }
  };

  return (
    <Button type="primary" onClick={handleDownload} loading={loading}>
      {loading ? 'Downloading...' : 'Download Excel'}
    </Button>
  );
};

export default DownloadButton;
import {
  createCategory,
  deleteCategory,
  getActiveCategory,
  getRemovedCategory,
  updateCategory,
  undoCategory,
} from "../services/categoryAPI";
import {
  Slice,
  createAsyncThunk,
  createSlice,
  isFulfilled,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const intialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: null,
  category: {},
  categories: [],
};

export const createCategReducer = createAsyncThunk(
  "category/create",
  async (categoryData, thunkApp) => {
    try {
      const res = await createCategory(categoryData);
      return res;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApp.rejectWithValue(message);
    }
  }
);

export const getCategoryReducerForActive = createAsyncThunk(
  "category/Active",
  async (pagenumber, thunkA) => {
    try {
      return await getActiveCategory(pagenumber);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkA.rejectWithValue(message);
    }
  }
);
export const getCategoryReducerForRemoved = createAsyncThunk(
  "category/removed",
  async (pagenumber, thunkA) => {
    try {
      return await getRemovedCategory(pagenumber);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkA.rejectWithValue(message);
    }
  }
);

export const updateCategoryReducer = createAsyncThunk(
  "category/update",
  async (pagenumber, thunkA) => {
    try {
      return await updateCategory(pagenumber);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkA.rejectWithValue(message);
    }
  }
);

export const deleteCategoryReducer = createAsyncThunk(
  "delet/category",
  async (para, thinkApi) => {
    try {
      return await deleteCategory(para);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thinkApi.rejectWithValue(message);
    }
  }
);

//
export const undoCategoryReducer = createAsyncThunk(
  "undo/category",
  async (para, thinkApi) => {
    try {
      return await undoCategory(para);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thinkApi.rejectWithValue(message);
    }
  }
);
//

const categorySlice = createSlice({
  name: "login",
  initialState: intialState,
  reducers: {
    editCategory: (state, payload) => {
      state.category = payload;
    },
  },

  extraReducers: (loginDat) => {
    loginDat
      .addCase(createCategReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCategReducer.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        toast.success("Category created Succesfully.");
      })
      .addCase(createCategReducer.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(getCategoryReducerForActive.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCategoryReducerForActive.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.categories = action.payload.data;
      })
      .addCase(getCategoryReducerForActive.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(getCategoryReducerForRemoved.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCategoryReducerForRemoved.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.categories = action.payload.data.categories;
      })
      .addCase(getCategoryReducerForRemoved.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(updateCategoryReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCategoryReducer.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        toast.success("Category Updated Succesfully.");
      })
      .addCase(updateCategoryReducer.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(deleteCategoryReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCategoryReducer.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        //
        const record1 = action.payload;
        state.categories = state.categories?.filter(
          (record) => record._id !== record1.data?.category?._id
        );
        console.log("PPPOOO", action.payload);
        toast.success("Category Deleted Successfully");
      })
      .addCase(deleteCategoryReducer.rejected, (state, action) => {
        state.isSuccess = false;
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(undoCategoryReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(undoCategoryReducer.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        //
        const record1 = action.payload;
        state.categories = state.categories?.filter(
          (record) => record._id !== record1.data?.category?._id
        );
        //
        toast.success("Category Acivated Successfully");
      })
      .addCase(undoCategoryReducer.rejected, (state, action) => {
        state.isSuccess = false;
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
        toast.error(action.payload);
      });
  },
});

export const isSuccess = (state) => state.category.isSuccess;
export const isError = (state) => state.category.isError;
export const isLoading = (state) => state.category.isLoading;
export const categoryReducer = (state) => state.category.category;

export const categories = (state) => state.category.categories;
export const { editCategory } = categorySlice.actions;

export default categorySlice.reducer;

import { useSelector } from "react-redux";
import Authenticated from "../src/components/layout/authenticatedLayout";
import DefaultLayout from "./pages/SignIn"

const App = () => {
  const { isLoggedIn } = useSelector((state) => state.login)
  // const isLoggedIn = localStorage.getItem("token")
  return (
    <div>
      {isLoggedIn ? <Authenticated /> : <DefaultLayout />}
    </div>

  )
}

export default App

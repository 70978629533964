import axios from "../plugins/axios";

export const assetValuationReport = async (payload) => {
  const response = await axios.post("/api/reports/asset/valuations", payload);
  return response;
};

export const assetValuationReportExcel = async (payload) => {
    const response = await axios.post("/api/reports/asset/valuations/excel", payload);
    return response;
  };
import { useNavigate, Link } from "react-router-dom";

import { Layout, Menu, Card, Row, Col, Statistic, Table } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getuserReducerForActive } from "../../store/userReducer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getActiveWarehouseReducer } from "../../store/warehouseReducer";
import { getCategoryReducerForActive } from "../../store/categoryReducers";
import { getAllTransactionReducer, getDailyTransactionReducer } from "../../store/transactionReducers";
import {  getInventoryInAllStockReducer } from "../../store/inventoryReducer";
import { getActiveProductReducer } from "../../store/productReducer";
const { Header, Sider, Content } = Layout;

const App = () => {
  const navigate = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInventoryInAllStockReducer());
    dispatch(getActiveProductReducer())
    dispatch(getuserReducerForActive());
    dispatch(getActiveWarehouseReducer())
    dispatch(getCategoryReducerForActive());
    dispatch(getAllTransactionReducer());
    dispatch(getDailyTransactionReducer());
    
  }, []);

  const { users } = useSelector((state) => state.user);
  const { warehouses } = useSelector((state) => state.warehouse);
  const { categories } = useSelector((state) => state.category);
  const { transactions,dailyTransactions } = useSelector((state) => state.transaction);
  const { allInventries } = useSelector((state) => state.inventory);
  const { products } = useSelector((state) => state.product);
  return (
    <div>
      {/* <Header className="text-xl pt-2" style={{ background: "#fff" }}>
          Welcome To Marna
        </Header> */}
      <Row gutter={16}>
        <Col span={8}>
          <Card style={{marginBottom:'10px'}}>
            <Statistic title="Total Users" value={users?.length} />
          </Card>
          <Card>
            <Statistic title="Total Items" value={allInventries?.length} />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{marginBottom:'10px'}}>
            <Statistic title="Total Transactions" value={transactions?.length} />
          </Card>
          <Card>
            <Statistic title="Daily Transactions" value={dailyTransactions?.length} />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{marginBottom:'10px'}}>
            <Statistic title="Total Warehouse" value={warehouses?.length} />
          </Card>
          <Card>
            <Statistic title="Total Categories" value={categories?.length} />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{marginBottom:'10px',marginTop:"10px"}}>
            <Statistic title="Total Products" value={products?.data?.length} />
          </Card>
          <Card>
            <Statistic title="Total Inventory" value={allInventries?.length} />
          </Card>
        </Col>
       
      </Row>
      
    </div>
  );
};

export default App;

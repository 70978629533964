import React, { useEffect, useState } from "react";
import {
    Button,
    Row,
    Upload,
    message,
    Col,
    Form,
    Input,
    Select,
    Modal,
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import {
    getInventorySearchReducer,
    getAllInventoryReducer,
    getInventoryReducer,
    getProductsSearchReducer,
    getInventoryInAllStockReducer
} from "../../store/inventoryReducer";

import TableA from "../../components/tables/table";
import Item from "antd/es/list/Item";
import { toast } from "react-toastify";
const { Option } = Select;
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const InventoryInAllStock = () => {
    const [form] = Form.useForm();
    const { outGoingInventory } = useSelector((state) => state.inventory);
    const { allInventries } = useSelector((state) => state.inventory);
    const dispatch = useDispatch();

    //const tableData = ()=>{if()}

    useEffect(() => {
        //    dispatch(getProductReducer());
        //    dispatch(getCategoryReducerForActive());
        //    dispatch(getActiveWarehouseReducer());
        dispatch(getInventoryInAllStockReducer());
    }, []);


    const calculateCurrentPrice = (purchasedPrice, depreciationRate, purchasedDate) =>{
        // Calculate the number of years since the item was purchased
        const currentDate = new Date();
        const timeDiff = currentDate.getTime() -  new Date(purchasedDate).getTime();
        const yearsOwned = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365.25));
      
        // Calculate the current price using the formula:
        // Current Price = Purchased Price * (1 - Depreciation Rate)^Years Owned
        const currentPrice = purchasedPrice * Math.pow(1 - depreciationRate, yearsOwned);
        return currentPrice;
      }

    let dataSource = []
    // setTimeout(() => {
    dataSource = Object.keys(allInventries).map((key) => ({
        key,
        ...allInventries[key],
    }));
    console.log("data", dataSource)
    // }, 300);

    const onReset = () => {
        form.resetFields();
    };
    const columnsA = [
        {
            title: "Product Name",
            width: "15%",
            render: (text, record) => <span>{record?.product?.name}</span>,
        },

        {
            title: "Purchased Price",
            width: "15%",
            render: (text, record) => <span>{record?.purchasedPrice}</span>,
        },
        {
            title: "Current Price",
            width: "15%",
            render: (text, record) => <span>{record?.purchasedPrice ? 
                calculateCurrentPrice(record?.purchasedPrice,record?.depreciationRate,record?.purchasedDate) : "Not available"
                }</span>,
        },

        {
            title: "Quantity",
            width: "20%",
            render: (text, record) => (
                <span>
                    {record.quantity?.map((item) => (
                        <li style={{textTransform:"capitalize"}}>
                            {item.name}: <b>{item.value}</b>
                        </li>
                    ))}
                </span>
            ),
        },
    ];
    return (
        <div className="ml-10">
            <div>
                <p className="text-xl mb-4">Inventory inAll Warehouses</p>
                <Row gutter={16}>
                    

                    <div>
                        <div>
                            <TableA columnnn={columnsA} dataaa={dataSource} />
                        </div>
                    </div>
                </Row>
            </div>
        </div>
    );
};
export default InventoryInAllStock;

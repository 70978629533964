import {
  getActiveWarehouseReducer,
  getRemovedWarehouseReducer,
  warehouses,
  editWarehouse,
  deleteWarehouseReducer,
  undoWarehouseReducer,
} from "../../store/warehouseReducer";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Button, Input, Select, Modal } from "antd";
import {
  PicRightOutlined,
  DeleteOutlined,
  EditOutlined,
  UndoOutlined,
} from "@ant-design/icons";

import { useHistory } from "react-router-dom";
import TableA from "../../components/tables/table";
import { useEffect, useState } from "react";
import { getActiveUsers } from "../../services/userAPI";
const ViewWarehouse = () => {
  const navigate = useHistory();
  const { warehouses, isLoading } = useSelector((state) => state.warehouse);
  const [warehouseId, setWarehouseId] = useState();

  const [statuss, setStatus] = useState("");
  const [actionss, setActionss] = useState("");

  const { crdentialDataForOne } = useSelector((state) => state.login);
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  useEffect(() => {
    setActionss(true);
    setStatus(false);
    dispatch(getActiveWarehouseReducer());
    dispatch(getActiveUsers);
  }, []);

  const handleWarehouseEdit = (row) => {
    dispatch(editWarehouse(row));
    navigate.push("/addwarehouse/" + row._id);
  };

  const handlWarehouseDelete = (warehoseData) => {
    setWarehouseId(warehoseData._id);
    Modal.confirm({
      centered: true,
      okCancel: "Cancle",
      okText: "Delete",
      onOk: () => dispatch(deleteWarehouseReducer({ id: warehoseData._id })),
      title: "Confirmation",
      content: "Are you sure to delete this Warehouse",
    });
  };
  //
  const handleWarehouseAtivate = (row) => {
    setWarehouseId(row._id);
    Modal.confirm({
      centered: true,
      okCancel: "Cancle",
      okText: "Activate",
      onOk: () => dispatch(undoWarehouseReducer({ id: row._id })),
      title: "Confirmation",
      content: "Are you sure to Undo this Warehouseeeeeee",
    });
  };

  const handlWarehouseSatuts = (event) => {
    if (event === "active") {
      setActionss(true);
      setStatus(false);
      dispatch(getActiveWarehouseReducer());
    } else if (event === "removed") {
      setActionss(false);
      setStatus(true);
      dispatch(getRemovedWarehouseReducer());
    }
  };
  //
  const [userRole, setUserRole] = useState("");
  const columenn = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
  ];

  if (crdentialDataForOne?.role == "admin") {
    columenn.push({
      title: "Actions",
      render: (text, record) => (
        <span>
          {actionss && (
            <Button
              onClick={() => handleWarehouseEdit(record)}
              style={{ marginRight: 8 }}
              icon={<EditOutlined />}
            />
          )}
          {actionss && (
            <Button
              onClick={() => handlWarehouseDelete(record)}
              icon={<DeleteOutlined />}
            ></Button>
          )}
          {statuss && (
            <Button
              className="ml-2"
              icon={<UndoOutlined />}
              onClick={() => handleWarehouseAtivate(record)}
            ></Button>
          )}
        </span>
      ),
    });
  }

  return (
    <div>
      <p className="text-xl mb-2">List of Warhouse</p>
      <Row gutter={20}>
        <Col xs={24} sm={24} lg={8}>
          <Input className="ml-2 w-full" placeholder="Search" />
        </Col>
        {crdentialDataForOne.role == "admin" ? (
          <>
            <Col xs={24} sm={24} lg={8}>
              <Select
                className="w-full "
                onChange={handlWarehouseSatuts}
                placeholder="Warehouse Status"
              >
                <option value="active" selected>
                  Active
                </option>
                <option value="removed">Removed</option>
              </Select>
            </Col>
            <Col xs={24} sm={24} lg={8}>
              <Button
                onClick={() => navigate.push("/addwarehouse")}
                className=" mb-2"
              >
                Add Warehouse
              </Button>
            </Col>
          </>
        ) : (
          ""
        )}
      </Row>
      <div>
        <TableA columnnn={columenn} isLoading={isLoading} dataaa={warehouses} />
      </div>
    </div>
  );
};
export default ViewWarehouse;

import { Row, Col, Button, Form, Input, Select, Alert } from "antd";
import { PhoneOutlined, MailOutlined, EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useState, React, useEffect } from "react";
import { createUserReducer, updateUserReducer } from "../../store/userReducer";
import { getWarehouseReducer } from "../../store/warehouseReducer";
import Password from "antd/es/input/Password";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
const { Option } = Select;

const UserReg = () => {
  const navigate = useHistory();
  const { isSuccess, isError } = useSelector((state) => state.user);
  const { useRRr } = useSelector((state) => state.user);
  const { warehouses } = useSelector((state) => state.warehouse);
  const { crdentialDataForOne } = useSelector((state) => state.login);

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [warehouse_name, setWarehouseName] = useState("");

  const [isValidPhonNo, setIsValidPhoneNo] = useState("");

  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getWarehouseReducer());
  // }, []);

  const { id } = useParams();
  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const [form] = Form.useForm();
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 116,
    },
  };

  useEffect(() => {
    if (id) {
      setTimeout(() => {
        setFirstName(useRRr?.first_name);
        setLastName(useRRr?.last_name);
        setPhone(useRRr?.phone);
        setEmail(useRRr?.email);
        setRole(useRRr?.role);
        setWarehouseName(useRRr?.warehouse_name);
      }, 400);
    }
  }, []);

  const handleFirstName = (ev) => {
    setFirstName(ev.target.value);
  };
  const handleLastName = (event) => {
    setLastName(event.target.value);
  };
  const handlePhone = (event) => {
    const isValid = validatePhoneNumber(event.target.value);
    setIsValidPhoneNo(isValid ? "" : "Phone No. must be exactly nine digts");
    if (event.target.value.length > 9) {
    } else {
      setPhone(event.target.value);
    }
  };

  const validatePhoneNumber = (value) => {
    const phoneNumberRegex = /^\d{9}$/;
    return phoneNumberRegex.test(value);
  };

  const handlEmail = (ev) => {
    setEmail(ev.target.value);
  };
  const handleRole = (ev) => {
    setRole(ev);
  };
  const handlWarehouse = (ev) => {
    setWarehouseName(ev);
  };

  const handlPassword = (eb) => {
    setPassword(eb.target.value);
  };

  const updateUser = () => {
    if (crdentialDataForOne._id === id) {
      toast("Can't update for yourself");
    } else {
      console.log("Login Id" + crdentialDataForOne);
      console.log("Id" + id);
      dispatch(
        updateUserReducer({
          first_name,
          last_name,
          phone,
          email,
          role,
          warehouse: warehouse_name,
          id,
        })
      );
    }
  };
  const isPasswordComplex = (password) => {
    // Define the regular expression pattern for password complexity
    const pattern =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    // Test the password against the pattern
    return pattern.test(password);
  };
  const handleFormSubmit = async () => {
    isPasswordComplex(password);
    dispatch(
      createUserReducer({
        first_name,
        last_name,
        password,
        phone,
        email,
        role,
        warehouse: warehouse_name,
      })
    );
  };

  const onReset = () => {
    form.resetFields();
    setFirstName("");
    setLastName("");
    setPassword("");
    setEmail("");
    setPhone("");
    setRole("");
  };

  return (
    <>
      <h1 className="text-xl flex justify-center pt-2 mb-2 ">
        {id ? "Update Staff Detail" : "Register New Staff"}
      </h1>

      <Row gutter={4} className="mr-10 flex justify-center">
        
        <Col span={8}>
          <Form
            {...layout}
            form={form}
            layout="vertical"
            name="control-hooks"
            style={{
              maxWidth: 600,
            }}
          >
            <Form.Item
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "First Name is required",
                },
              ]}
            >
              <Input
                name="first_name"
                placeholder="First Name"
                value={first_name}
                onChange={handleFirstName}
              />
            </Form.Item>
            <Form.Item
              label="Father Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                name="last_name"
                placeholder="Last Name"
                value={last_name}
                onChange={handleLastName}
              />
            </Form.Item>

            {id ? (
              ""
            ) : (
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Password is Required",
                  },
                ]}
              >
                
                <Input.Password
                size="small"
                value={password} // P is capital
                onChange={handlPassword}
        placeholder="password"
        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
      />
              </Form.Item>
            )}
          </Form>
        </Col>
        <Col span={8}>
          <Form
            {...layout}
            form={form}
            layout="vertical"
            name="control-hooks"
            style={{
              maxWidth: 600,
            }}
          >
            <Form.Item
              label="Email"
              rules={[
                {
                  required: true,
                },
                {
                  type: "email",
                },
              ]}
            >
              <Input
                addonBefore={<MailOutlined style={{ width: "20px" }} />}
                type="email"
                name="email"
                value={email}
                onChange={handlEmail}
                placeholder="name@example.com"
              />
            </Form.Item>
            <Form.Item
              label="Phone No"
              rules={[
                {
                  required: true,
                },
                {
                  type: Number,
                },
              ]}
            >
              <Input
                addonBefore="+251"
                placeholder="Phone Number"
                type="number"
                name="phone"
                value={phone}
                onChange={handlePhone}
              />
            </Form.Item>

            <Form.Item
              label="Role"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                name={role}
                value={role}
                size="large"
                placeholder="Give Role to the staff"
                // style={{ height: "40px" }}

                onChange={handleRole}
              >
                <Option value="" disabled>Select role</Option>
                <Option value="admin">Admin</Option>
                <Option value="warehouseAdmin">Warehouse Admin</Option>
                <Option value="auditor">Auditor</Option>
              </Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.role !== currentValues.role
              }
            >
              {role == "warehouseAdmin" ? (
                <Form.Item
                  name="warehouseName"
                  label="Warehouse"
                  className="mr-2"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Warehouse name"
                    name="warehouseName"
                    value={warehouses}
                    onChange={handlWarehouse}
                  >
                    {warehouses?.map((item, key) => (
                      <option key={key} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </Form.Item>
              ) : null}
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button
                className="mr-4"
                htmlType="button"
                onClick={() => onReset()}
                style={{marginRight:"10px"}}
              >
                Reset
              </Button>
              <Button
                htmlType="submit"
                onClick={id ? updateUser : handleFormSubmit}
              >
                {id ? "Update Staff Data" : "Register New Staff"}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default UserReg;
